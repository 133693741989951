import ApiService from "./ApiService";
import axios from "axios";
import moment from "moment";
import i18n from "../i18n";
import { onlyDigits } from "../shared/helpers/documentHelper";
const apiService = new ApiService();
export default class ValidationService {
  constructor() {}

  async check_if_email_exists(id, email) {
    var emailExists = false;
    await apiService
      .postRequest(
        `user/check-email`,
        { User: { Email: email, Id: id } },
        false
      )
      .then((resp) => {})
      .catch((error) => {
        emailExists = true;
      });
    return emailExists;
  }
  async check_if_social_number_exists(
    id,
    socialNumber,
    userType,
    onlyNumbers = true
  ) {
    var socialNumberExists = false;
    await apiService
      .postRequest(
        `${userType}/check-social-number`,
        {
          Id: id,
          SocialNumber: onlyNumbers ? onlyDigits(socialNumber) : socialNumber,
        },
        false
      )
      .then((resp) => {})
      .catch((error) => {
        socialNumberExists = true;
      });
    return socialNumberExists;
  }
  passwordsMatch(p1, p2) {
    return p1 == p2;
  }
  special_required(v) {
    return /[*&$!@#?]/.test(v);
  }
  uppercase_required(v) {
    return /[A-Z]/.test(v); //|| this.$t("uppercase_required");
  }
  number_required(v) {
    return /[0-9]/.test(v); //|| this.$t("number_required");
  }
  lowercase_required(v) {
    return /[a-z]/.test(v); //|| this.$t("lowercase_required");
  }
  phone_valid(v) {
    return v.length == "(##) #####-####".length;
  }
  accent_valid(v) {
    return !/[À-ÖØ-öø-ÿ]/.test(v);
  }
  email_valid(v) {
    return (
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        v
      ) || i18n.t("format_invalid")
    );
  }
  social_number_valid(v) {
    return (
      (v.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").length ==
        11 &&
        this.cpf_test(
          v.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
        )) ||
      (v.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "").length ==
        14 &&
        this.cnpj_test(
          v.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
        ))
    );
  }
  cpf_test(strCPF) {
    strCPF = strCPF.replace(/\D/g, "");
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;
    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }
  cnpj_test(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }
  validDate(date, includes_day = true) {
    let date_parsed;
    if (includes_day) {
      date_parsed = moment(date, "DD/MM/YYYY");
    } else {
      date_parsed = moment(date, "MM/YYYY");
    }
    return () =>
      !date ||
      (date && date_parsed.year() > 1600 && date_parsed.isValid()) ||
      i18n.t("valid_date");
  }
  async check_if_social_number_of_investment_vehicle_exists(socialNumber) {
    var socialNumberExists = false;
    await apiService
      .getRequest(
        `alphaInvestor/check-social-number-of-investment-vehicle?socialNumber=` +
          socialNumber
      )
      .then((resp) => {})
      .catch((error) => {
        socialNumberExists = true;
      });
    return socialNumberExists;
  }
}
