<template>
  <v-container v-if="!loading" id="add-user" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-account"
      :title="page_title()"
      class="px-5 py-3"
    >
      <v-form
        @submit.stop.prevent="edit_user()"
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        class="mt-5"
      >
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              :label="$t('name')"
              :rules="[required]"
              v-model="user.Name"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :label="$t('last_name')"
              :rules="[required]"
              v-model="user.LastName"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="partners"
              :loading="partnersLoading"
              :label="$tc('partner', 1)"
              item-text="Email"
              item-value="Id"
              color="black"
              v-model="user.PartnerId"
              :rules="[required]"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              @keydown.space.prevent
              :label="$t('cpf')"
              :rules="[required, social_number_valid]"
              v-model="user.SocialNumber"
              v-mask="['###.###.###-##']"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              @keydown.space.prevent
              :label="$t('email')"
              :rules="[required]"
              v-model="user.Email"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :label="$t('birth_date')"
              v-model="date"
              v-mask="['##/##/####']"
              prepend-inner-icon="mdi-calendar"
              @blur="user.Birth = gs.parseDate(date)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.PostCode"
              placeholder="00000-000"
              @keyup="post_code_changed"
              v-mask="['#####-###']"
              type="tel"
              :rules="[required]"
              class="purple-input"
              :label="$t('post_code')"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              class="purple-input"
              :label="$t('state')"
              v-model="user.State"
              :rules="[required]"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="user.City"
              :rules="[required]"
              :label="$t('city')"
              class="purple-input"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              v-model="user.Address"
              :rules="[required]"
              :label="$t('address')"
              class="purple-input"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              :rules="[required]"
              :label="$t('number')"
              class="purple-input"
              v-model="user.AddressNumber"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              :label="$t('complement')"
              class="purple-input"
              v-model="user.AddressComplement"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <span>{{ $t("invest_value") }}</span>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <span>{{ $t("selected_portfolio") }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" class="pt-0">
            <money
              class="register-text-field"
              :class="{
                'invalid-field':
                  !user.InvestValue ||
                  user.InvestValue < portfolioSelected.MinimumValue,
              }"
              prefix="R$"
              thousand="."
              thousands="."
              decimal=","
              v-model="user.InvestValue"
            ></money>
            <p
              style="color: red; font-size: 10px"
              class="mb-4 mt-n4 ml-2"
              v-if="
                !portfolioLoading &&
                user.InvestValue < portfolioSelected.MinimumValue
              "
            >
              {{
                $t("alpha_register_minimum", {
                  value: portfolioSelected.MinimumValueLabel,
                })
              }}
            </p>
          </v-col>
          <v-col cols="12" md="6" class="pt-0">
            <v-select
              :items="portfolioOptions"
              :loading="portfolioLoading"
              item-text="number"
              color="black"
              return-object
              outlined
              dense
              v-model="portfolioSelected"
              :rules="[required]"
              readonly
            >
            </v-select>
          </v-col>
        </v-row>
        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="saveLoading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="secondary"
            min-width="100"
            @click="$router.go(-1)"
            >{{ $t("go_back") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </base-material-card>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import moment from "moment";
import axios from "axios";

export default {
  name: "ManageAlphaInvestor",

  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    vs: new ValidationService(),
    loading: false,
    saveLoading: false,
    portfolioLoading: false,
    portfolioOptions: [],
    portfolioSelected: {},
    partners: [],
    partnersLoading: false,
    edit: false,
    date: null,
    success: null,
    alert_msg: null,
    user: null,
    tab: 0,
  }),

  computed: {
    social_number_valid() {
      return (value) =>
        (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          .length == 11 &&
          this.vs.cpf_test(
            value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          )) ||
        (value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          .length == 14 &&
          this.vs.cnpj_test(
            value.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
          )) ||
        this.$t("format_invalid");
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
  },

  watch: {
    "user.InvestValue"() {
      this.update_portfolio();
    },
  },

  methods: {
    async get_partners() {
      this.partnersLoading = true;
      await this.apiService
        .getRequest("partner/list")
        .then((resp) => {
          this.partners = JSON.parse(resp);
          this.partnersLoading = false;
        })
        .catch((error) => {
          this.partnersLoading = false;
        });
    },
    async update_portfolio() {
      if (this.portfolioLoading || this.portfolioOptions.length == 0) {
        return;
      }
      this.portfolioLoading = true;
      if (this.user.InvestValue < 1000000) {
        this.user.SelectedPortfolioId = this.portfolioOptions[0].id;
      } else if (
        this.user.InvestValue >= 1000000 &&
        this.user.InvestValue < 5000000
      ) {
        this.user.SelectedPortfolioId = this.portfolioOptions[1].id;
      } else if (this.user.InvestValue >= 5000000) {
        this.user.SelectedPortfolioId = this.portfolioOptions[2].id;
      }
      this.portfolioSelected = this.portfolioOptions.filter(
        (x) => x.id == this.user.SelectedPortfolioId
      )[0];
      this.portfolioLoading = false;
    },
    async get_portfolio_options() {
      this.portfolioLoading = true;
      await this.apiService
        .getRequest("alphaportfolio/list-all")
        .then((result) => {
          this.portfolioOptions = result.content;
          if (this.portfolioOptions.length > 0) {
            var index = 1;
            if (this.user.SelectedPortfolioId) {
              index = this.portfolioOptions
                .map((x) => x.id)
                .indexOf(this.user.SelectedPortfolioId);
              if (index < 0) {
                index = 1;
              }
            }
            this.portfolioSelected = this.portfolioOptions[index];
            this.user.SelectedPortfolioId = this.portfolioSelected.id;
          }
        })
        .catch((err) => {});
      this.portfolioLoading = false;
    },
    post_code_changed() {
      var cep = this.user.PostCode.replace("-", "");
      if (cep.length >= 8) {
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then((resp) => {
            var json = resp.data;
            this.user.PostCode = json.cep;
            this.user.State = json.uf;
            this.user.City = json.localidade;
            this.user.Address = json.logradouro;
          })
          .catch((err) => {});
      }
    },
    reset_alert: function () {
      this.success = null;
      this.alert_msg = null;
    },
    show_alert: function (success, msg) {
      this.success = success;
      this.alert_msg = msg;
    },
    page_title: function () {
      if (this.edit && !this.loading) {
        return this.gs.show_full_name(this.user);
      } else if (!this.edit) {
        return this.$t("add_user");
      } else {
        return "";
      }
    },
  },
};
</script>
